import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const LinkedInBtn = ({ id, text, onClick, disabled }) => (
  <button disabled={disabled} id={id} onClick={onClick} className="linkedInBtn">
    {text}
    <span className="linkedInBtnIcon"></span>
  </button>
);

LinkedInBtn.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

LinkedInBtn.defaultProps = {
  id: "",
  text: "Ok",
  disabled: false,
};

export default LinkedInBtn;
