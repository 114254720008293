import React, { Component } from "react";
import { isEmpty } from "lodash";
import { addError, validateField } from "../../utils/signupHelper";
import MiniLoader from "../../components/MiniLoader";
import { checkUserEmailStatus } from "../../api/lynkClient";
import {
  EMAIL_WITH_LINKEDIN_EXISTS,
  USER_WITH_EMAIL_EXISTS,
  EMAIL_ALREADY_EXISTS,
} from "./messages";
class SignupFormHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: this.props.userEmail || "",
      userPassword: "",
      userConfirmPassword: "",
      userBasicTnc: true,
      userBasicTncLinkedIn: true,
      linkedInTncModal: false,
      formErrors: {},
      captcha: null,
      showFullSignup: false,
      magicLinkResent: false,
      magicLinkForLinkedInEmailResent: false,
      showMiniLoader: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    const fieldErrors = this.validateFullForm(this.state);
    this.setState({ formErrors: fieldErrors });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.linkedin &&
      prevProps.linkedin.finished !== this.props.linkedin.finished &&
      this.props.linkedin.linkedinError === EMAIL_ALREADY_EXISTS
    ) {
      this.checkAccountStatusByEmail(
        this.props.linkedin.linkedinEmail,
        null,
        "linkedin"
      );
    }
  }

  onBlur = ({ target }) => {
    let error;
    if (target.name === "userConfirmPassword") {
      error = validateField(target.name, {
        userPassword: this.state.userPassword,
        userConfirmPassword: this.state.userConfirmPassword,
      });
    } else {
      error =
        target.type === "checkbox"
          ? validateField(target.name, target.checked)
          : validateField(target.name, target.value);
    }
    const fieldErrors = addError(this.state.formErrors, target.name, error);
    this.setState({ formErrors: fieldErrors });
  };

  onCreateAccount = () => {
    const { userEmail, userPassword, captcha } = this.state;
    this.props.onCreateAccountSubmit(userEmail, userPassword, captcha);
  };

  onKeyPress = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      return !this.state.showFullSignup && !this.state.formErrors.userEmail;
    }
  };

  getErrorClass = (field) => (this.hasError(field) ? "error" : null);

  handleOnChange = ({ target }) => {
    const { name } = target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [name]: value }, () => {
      this.setState({
        formErrors: this.validateFullForm(this.state),
        magicLinkResent: false,
      });
    });
  };

  validateFullForm = (values) => {
    let error = {};
    const fullError = values.formErrors;
    const fields = [
      "userEmail",
      "userPassword",
      "userConfirmPassword",
      // "userBasicTnc",
    ];
    fields.forEach((field) => {
      if (field === "userConfirmPassword") {
        error = validateField(field, {
          userPassword: values.userPassword,
          userConfirmPassword: values.userConfirmPassword,
        });
      } else {
        error = validateField(field, values[field]);
      }
      fullError[field] = error;
    });
    return fullError;
  };

  hasError = (field) =>
    !isEmpty(this.state.formErrors[field]) &&
    this.state.formErrors[`${field}Dirty`];

  openLinkedInTncModal = () => {
    this.setState({ linkedInTncModal: true });
  };

  closeLinkedInTncModal = () => {
    this.setState({
      linkedInTncModal: false,
      userBasicTncLinkedIn: true,
    });
  };

  checkAccountStatusByEmail = (email, captchaInvisible, source = "email") => {
    this.showMiniLoading();
    return checkUserEmailStatus({
      email,
      captchaInvisible,
      source,
    })
      .then((response) => {
        this.handleByAccountType(response.data, source);
      })
      .catch((error) => {
        console.log("Error: ", error);
        this.setState({ errorMessage: error.message });
      })
      .finally(() => {
        this.hideMiniLoading();
      });
  };
  handleByAccountType = (expertStatus, source) => {
    const { hasLinkedInLogin, hasPassword, emailExists } = expertStatus;
    // NEW USER
    if (!emailExists) {
      return this.setState({
        showFullSignup: true,
        magicLinkResent: false,
        errorMessage: "",
        magicLinkForLinkedInEmailResent: false,
      });
    }
    // EXISTING USER WITH PASSWORD/LINKEDIN
    if (emailExists && (hasLinkedInLogin || hasPassword)) {
      if (source === "linkedin") {
        return this.setState({ errorMessage: EMAIL_WITH_LINKEDIN_EXISTS });
      }
      this.setState({
        formErrors: {
          userEmail: USER_WITH_EMAIL_EXISTS,
          userEmailDirty: true,
        },
      });
    }
    // EXISTING USER WITH NO PASSWORD/LINKEDIN
    if (emailExists && !hasLinkedInLogin && !hasPassword) {
      if (source === "linkedin") {
        return this.setState({
          magicLinkResent: false,
          magicLinkForLinkedInEmailResent: true,
          showFullSignup: false,
        });
      }
      return this.setState({
        magicLinkResent: true,
        magicLinkForLinkedInEmailResent: false,
        showFullSignup: false,
      });
    }
  };

  captchaOnChange = (captcha) => this.setState({ captcha });

  continueButtonClickHandler = () => {
    return this.checkAccountStatusByEmail(this.state.userEmail);
  };

  hideMiniLoading = () => this.setState({ showMiniLoader: false });

  showMiniLoading = () => this.setState({ showMiniLoader: true });
  render() {
    const formProps = {
      onBlur: this.onBlur,
      getErrorClass: this.getErrorClass,
      handleOnChange: this.handleOnChange,
      hasError: this.hasError,
      onCreateAccount: this.onCreateAccount,
      openLinkedInTncModal: this.openLinkedInTncModal,
      closeLinkedInTncModal: this.closeLinkedInTncModal,
      continueButtonClickHandler: this.continueButtonClickHandler,
      onKeyPress: this.onKeyPress,
      captchaOnChange: this.captchaOnChange,
      siteKey: this.props.siteKey,
      checkAccountStatusByEmail: this.checkAccountStatusByEmail,
    };

    return (
      <React.Fragment>
        {(this.state.showMiniLoader || this.props.loading) && <MiniLoader />}
        {this.props.children(this.state, formProps)}
      </React.Fragment>
    );
  }
}

export default SignupFormHelper;
