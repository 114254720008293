import React from "react";
import styles from "./styles.module.scss";
import crafttLogo from "../../images/signup/craftt-neutral-logo.svg";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <img src={crafttLogo} alt="logo" />
    </div>
  );
};

export default Footer;
