import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ButtonMVP = ({ id, onClick, children, disabled, className }) => {
  const btnStyle = className
    ? `${styles[className]} ${styles.btn}`
    : styles.btn;
  return (
    <div className={styles.btnWrap}>
      <button
        id={id}
        onClick={onClick}
        disabled={disabled}
        className={btnStyle}
      >
        {children}
      </button>
    </div>
  );
};

ButtonMVP.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ButtonMVP.defaultProp = {
  disabled: true,
  className: "",
};

export default ButtonMVP;
