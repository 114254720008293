import loadingSvg from "./spinner.svg";

function Loading() {
  return (
    <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
      <img src={loadingSvg} alt="loading" />
    </div>
  );
}

export default Loading;
