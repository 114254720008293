import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Statistic } from "antd";
import Button from "../ButtonMVP";
import "./style.css";

const { Countdown } = Statistic;
export default function Login2fa({
  loading,
  loginId,
  password,
  submit,
  tfaInfo = {},
  resend2faCode,
  errorMsg,
}) {
  const deadline = new Date(tfaInfo.codeValidUntil).getTime();
  const [code, setCode] = useState("");
  const [isCodeExpired, setCodeExpired] = useState(false);
  const [serverError, setServerError] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    const codeInfo = e.target.value.trim().replace(/[^\d]/g, "");

    if (codeInfo !== code && codeInfo.length <= 6) {
      setCode(codeInfo);
    }
  };

  useEffect(() => {
    if (deadline > new Date().getTime()) {
      setCodeExpired(false);
    } else {
      setCodeExpired(true);
    }
  }, [deadline]);

  const handleSubmit = (e) => {
    const state = {};
    if (!code) {
      setServerError("Please enter authentication code");
      return;
    }
    state.serverError = "";
    setServerError("");
    submit(code);
  };

  return (
    <form>
      <div className="signup__email-container _2fa">
        <p>
          <FormattedMessage
            id="login_mfa.enterToken"
            defaultMessage="To complete the sign-in process, please enter the code sent to your registered email."
          />
        </p>
        <div style={{ color: "red", margin: "10px 0px -20px 0px" }}>
          {serverError || errorMsg}
        </div>
        <div className={"form-group"}>
          <input
            type="text"
            className="form-control input"
            name="authCode"
            placeholder="6 digit authentication code "
            spellCheck="false"
            autoComplete="off"
            autoFocus={true}
            onChange={handleChange}
            value={code}
          />
        </div>
        <div className="form-group" style={{ marginBottom: "unset" }}>
          {!isCodeExpired && (
            <Countdown
              title="Resend in"
              value={deadline}
              format="mm:ss"
              suffix={
                <span style={{ margin: "0px 10px", fontWeight: "normal" }}>
                  minutes
                </span>
              }
              valueStyle={{
                color: "#000",
                fontWeight: "700",
              }}
              onFinish={() => setCodeExpired(true)}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "-20px",
            }}
          >
            {isCodeExpired && (
              <Button
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  resend2faCode();
                }}
                className="plain"
                btnType="success"
                style={{
                  marginTop: "17px",
                  marginBottom: "unset",
                }}
              >
                Resend
              </Button>
            )}
            <div style={{ marginLeft: "auto" }}>
              <Button
                disabled={loading || code.length !== 6}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit(e);
                }}
                btnType="success"
                btnStyle={{
                  marginTop: "17px",
                  marginBottom: "unset",
                }}
              >
                {loading ? "Verifying..." : "Verify"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
