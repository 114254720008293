import React from "react";
import ErrorFrame from "../../components/ErrorFrame";
import WizardFrame from "../../components/WizardFrame";
import { get } from "lodash";
const MagicLynkError = ({ magicCodeError }) => {
  const errorTitle = get(magicCodeError, "name", "Please ask for a new one.");
  const errorSubtitle = get(
    magicCodeError,
    "message",
    "Please ask for a new one."
  );
  let pageError = "";
  if (errorTitle === "MagicCode is expired!") {
    pageError = <ErrorFrame title={errorTitle} subtitle={errorSubtitle} />;
  } else {
    pageError = <WizardFrame title={errorTitle} subtitle={errorSubtitle} />;
  }
  return <div data-testid="magic-lynk-error">{pageError}</div>;
};
export default MagicLynkError;
