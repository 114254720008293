import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import style from "./styles.module.scss";

export default function Header({ isLynkCircle, circle }) {
  return (
    <div className={style.wrapper}>
      <div className={style.content}>
        <img
          src={isLynkCircle ? get(circle, "rectangleLogo", "") : undefined}
          style={
            isLynkCircle
              ? {
                  maxWidth: "288px",
                  width: "100%",
                }
              : undefined
          }
          alt={isLynkCircle ? get(circle, "circle.circleName", "") : ""}
        />
      </div>
    </div>
  );
}
Header.propTypes = {
  isLynkCircle: PropTypes.bool,
  circle: PropTypes.object,
};
