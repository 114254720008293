import React from "react";
import WizardFrame from "../../components/WizardFrame";
// import Debugger from 'utils/debug';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error: error.message });
    // Debugger.captureException(error, info);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <WizardFrame title="Something went wrong" subtitle={this.state.error} />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
