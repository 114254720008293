import Redirect from "./containers/Redirect";

const redirect = {
  title: "Redirecting",
  component: Redirect,
  url: "/redirect",
};

const routes = [redirect];
export default routes;
