import React from "react";
import { Tooltip as TooltipComp } from "antd";
import PropTypes from "prop-types";
import "antd/lib/tooltip/style/index.css";
import "./styles.scss";

const Tooltip = ({ maxWidth, fontSize, tooltipText, children }) => (
  <TooltipComp overlayStyle={{ maxWidth, fontSize }} title={tooltipText}>
    {children}
  </TooltipComp>
);

Tooltip.propTypes = {
  maxWidth: PropTypes.string,
  fontSize: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node,
};

export default Tooltip;
