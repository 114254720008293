import React from "react";
import PropTypes from "prop-types";
import getQueryParameter from "./getQueryParameter";
import reset, { resetOnUserCancel } from "./reset";

export default class LinkedIn extends React.Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    onError: PropTypes.func,
    className: PropTypes.string,
    text: PropTypes.node,
    scope: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    onError: () => {},
  };

  componentDidMount() {
    this.restart();
  }

  start = async () => {
    window.location.href = `/oauth/linkedin?originalUrl=${
      window.location.pathname
    }&${window.location.search.substring(1)}`;
  };

  restart = () => {
    // When user goes to LinkedIn page and hits cancel from LinkedIn
    if (getQueryParameter("error") === "user_cancelled_login") {
      /**
       * When user hits cancel from LinkedIn login and returns to magic link signup,
       * need to clear URL error params upon returning to the signup page. Since we are using ${window.location.pathname}&${window.location.search.substring(1)}
       * params should be reset for next LinkedIn login attempt.
       */
      return resetOnUserCancel();
    }
    if (!getQueryParameter("code")) return;
    if (!getQueryParameter("state")) return;
    const code = getQueryParameter("code");
    const state = getQueryParameter("state");
    const liErrorMessage = getQueryParameter("liError");
    reset();
    if (liErrorMessage) {
      this.props.onError(liErrorMessage);
    } else {
      this.props.callback({ code, state });
    }
  };

  render() {
    return this.props.children(this.start);
  }
}
