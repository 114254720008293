import React from "react";
import loadingSvg from "../Loading/spinner.svg";
import styles from "./styles.css";

const MiniLoader = () => (
  <div className={styles.miniLoader}>
    <center className={styles.loading}>
      <img width="45" src={loadingSvg} alt="loading" />
    </center>
  </div>
);

export default MiniLoader;
