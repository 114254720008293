import { isEmpty, isEqual, get } from "lodash";
import { validateEmail } from "../utils/emailValidation";
import { validatePassword } from "../utils/passwordValidation";

export const onLinkedInDataChange = (linkedinData, followupFn) => {
  const data = {
    name: linkedinData.firstName,
    lastname: linkedinData.lastName,
    email: linkedinData.emailAddress,
    linkedinId: linkedinData.id,
    profilePicURL: linkedinData.profilePicURL,
  };
  followupFn(data);
};

export const getErrorClass = (field, errorFn) => {
  return errorFn(field) ? "error" : null;
};

export const addError = (formFieldErrors, field, error) => {
  const fieldErrors = { ...formFieldErrors };
  fieldErrors[field] = error;
  fieldErrors[`${field}Dirty`] = true;
  return fieldErrors;
};

export const checkNoFieldErrors = (formErrors) =>
  Object.values(formErrors).every((fieldError) => isEmpty(fieldError));

export const validateConfirmPassword = (password, confirmPassword) =>
  password === confirmPassword;

export const validateField = (fieldName, value) => {
  let fieldValidationError;
  switch (fieldName) {
    case "userEmail":
      fieldValidationError = validateEmail(value)
        ? undefined
        : "Email is invalid";
      break;
    case "userPassword":
      fieldValidationError = validatePassword(value);
      break;
    case "userConfirmPassword": {
      const { userPassword, userConfirmPassword } = value;
      fieldValidationError = validateConfirmPassword(
        userPassword,
        userConfirmPassword
      )
        ? undefined
        : "Passwords do not match";
      break;
    }
    case "userBasicTnc":
      fieldValidationError = value ? undefined : "Please accept TnC";
      break;
    default:
      break;
  }
  return fieldValidationError;
};

export const checkPropChangedWithSignupStatus = (
  prevProp,
  nowProp,
  signupStatus
) => prevProp !== nowProp && nowProp === signupStatus;

export const checkPropsEqual = (prevProps, nowProps) => {
  const prevPropValueArr = propsToCheckForMagicLynkPrefilling.map((propName) =>
    get(prevProps, propName)
  );
  const nowPropValueArr = propsToCheckForMagicLynkPrefilling.map((propName) =>
    get(nowProps, propName)
  );
  return isEqual(prevPropValueArr, nowPropValueArr);
};

export const propsToCheckForMagicLynkPrefilling = [
  "magiclynk.uiSignupStatus",
  "user.currentCircleId",
  "user.myCircles",
];
