import { validateEmail } from "./emailValidation";
import { validatePassword } from "./passwordValidation";
import { FIELD_NAMES } from "./constants";
import _ from "lodash";

const validateConfirmPassword = (password, confirmPassword) =>
  password === confirmPassword;

export const validateField = (fieldName, value) => {
  let fieldValidationError;
  switch (fieldName) {
    case FIELD_NAMES.LoginMagicLynk.EMAIL:
      fieldValidationError = validateEmail(value)
        ? undefined
        : FIELD_NAMES.LoginMagicLynkError.EMAIL;
      break;
    case FIELD_NAMES.LoginMagicLynk.PASSWORD:
      fieldValidationError = validatePassword(value);
      break;
    case FIELD_NAMES.LoginMagicLynk.CONFIRMPASSWORD: {
      const { password, confirmPassword } = value;
      fieldValidationError = validateConfirmPassword(password, confirmPassword)
        ? undefined
        : FIELD_NAMES.LoginMagicLynkError.CONFIRMPASSWORD;
      break;
    }
    case FIELD_NAMES.LoginMagicLynk.BASICTNC:
      fieldValidationError = value
        ? undefined
        : FIELD_NAMES.LoginMagicLynkError.BASICTNC;
      break;
    default:
      break;
  }
  return fieldValidationError;
};

const checkPasswordEmpty = (fieldName, password) =>
  fieldName === FIELD_NAMES.LoginMagicLynk.PASSWORD && !password;

export const validateFullForm = (data, errors = [], fields = []) => {
  let error = "";
  fields.forEach((field) => {
    if (field === FIELD_NAMES.LoginMagicLynk.EMAIL) {
      error = validateEmail(data[field])
        ? ""
        : FIELD_NAMES.LoginMagicLynkError.EMAIL;
    }
    if (field === FIELD_NAMES.LoginMagicLynk.PASSWORD) {
      error = checkPasswordEmpty(field, _.trim(data[field]))
        ? FIELD_NAMES.LoginMagicLynkError.PASSWORD
        : "";
    }
    if (field === FIELD_NAMES.LoginMagicLynk.CONFIRMPASSWORD) {
      error = validateField(field, {
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
    } else {
      error = validateField(field, data[field]);
    }
    errors[field] = error;
  });
  return errors;
};

export const getErrorClass = (field, errorFn) => {
  return errorFn(field) ? "error" : null;
};
export const hasError = (field, fieldErrors = []) => {
  return !_.isEmpty(fieldErrors[field]) && fieldErrors[`${field}Dirty`];
};
export const checkNoformErrors = (formErrors = []) => {
  return Object.values(formErrors).every((fieldError) => _.isEmpty(fieldError));
};
