/* eslint-disable import/no-anonymous-default-export */
import getQueryParameter from "./getQueryParameter";

export default function () {
  const code = getQueryParameter("code");
  const state = getQueryParameter("state");
  let newURL = window.location.href.replace(`code=${code}&state=${state}`, "");
  if (newURL.endsWith("?")) {
    newURL = newURL.slice(0, -1);
  }
  window.history.replaceState(null, null, newURL);
}

export const resetOnUserCancel = () => {
  let newURL = `${window.location.origin}${window.location.pathname}`;
  const magicCode = getQueryParameter("c");
  if (magicCode) {
    newURL = `${newURL}?c=${magicCode}`;
  }
  if (newURL.endsWith("?")) {
    newURL = newURL.slice(0, -1);
  }
  window.history.replaceState(null, null, newURL);
};
