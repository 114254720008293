import {
  MIN_USERNAME_LENGTH,
  MAX_USERNAME_LENGTH,
  RESERVED_USERNAMES,
} from "./constants";
// import { FormattedMessage } from "react-intl";

export function getPasswordConfig(license, config) {
  return {
    isEnterprise: config.BuildEnterpriseReady === "true",
    isLicensed: license.IsLicensed === "true",
    isPasswordRequirements: license.PasswordRequirements === "true",
    minimumLength: parseInt(config.PasswordMinimumLength, 10),
    requireLowercase: config.PasswordRequireLowercase === "true",
    requireUppercase: config.PasswordRequireUppercase === "true",
    requireNumber: config.PasswordRequireNumber === "true",
    requireSymbol: config.PasswordRequireSymbol === "true",
  };
}

export function isValidPassword(password, minPasswordLength, errorMsg) {
  if (
    !password ||
    password.length < minPasswordLength ||
    !/[^A-Za-z0-9]/.test(password) ||
    !/\d/.test(password) ||
    !/[A-Z]/.test(password)
  )
    return errorMsg;

  return "";
}

export function isValidUsername(name) {
  var error = "";
  if (!name) {
    error = "This field is required";
  } else if (
    name.length < MIN_USERNAME_LENGTH ||
    name.length > MAX_USERNAME_LENGTH
  ) {
    error =
      "Must be between " +
      MIN_USERNAME_LENGTH +
      " and " +
      MAX_USERNAME_LENGTH +
      " characters";
  } else if (!/^[a-z0-9.\-_]+$/.test(name)) {
    error =
      "Must contain only letters, numbers, and the symbols '.', '-', and '_'.";
  } else if (!/[a-z]/.test(name.charAt(0))) {
    //eslint-disable-line no-negated-condition
    error = "First character must be a letter.";
  } else {
    for (var i = 0; i < RESERVED_USERNAMES.length; i++) {
      if (name === RESERVED_USERNAMES[i]) {
        error = "Cannot use a reserved word as a username.";
        break;
      }
    }
  }
  return error;
}
