// import { connect } from "react-redux";
// import { getCurrentUser } from "mattermost-redux/selectors/entities/users";
// import { getMe } from "mattermost-redux/actions/users";
import MfaSetup from "./mfa_setup";
import { generateMfaSecret } from "../../../api/lynkClient";
import React, { useState } from "react";
import PasswordGate from "../mfa_password";

export default function Page({ currentUser }) {
  const [secret, setSecret] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [token, setToken] = useState("");
  // const [code, setCode] = useState("");

  const onSubmit = (password) => {
    return generateMfaSecret(password).then(
      ({ data: { secret, qrCode, token } = {}, success }) => {
        this.setState({ secret, qrCode, token });
        setSecret(secret);
        setQrCode(qrCode);
        setToken(token);
      }
    );
  };
  const mfaInfo = {
    secret,
    qrCode,
    token,
  };
  if (!currentUser.mfaActive)
    return (
      <React.Fragment>
        <MfaSetup {...this.props} />
      </React.Fragment>
    );
  return (
    <React.Fragment>
      <PasswordGate
        onSubmit={onSubmit}
        user={currentUser}
        render={() => <MfaSetup {...this.props} {...mfaInfo} />}
      />
    </React.Fragment>
  );
}

export const Form = MfaSetup;
