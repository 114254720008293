import React, { useState, useEffect } from "react";
import { get, isEmpty } from "lodash";
import "./login.scss";
import TextFieldWithLabel from "../TextFieldWithLabel";
import PasswordFieldWithLabel from "../PasswordFieldWithLabel";
import {
  checkNoformErrors,
  getErrorClass,
  validateFullForm,
  validateField,
} from "../../utils/fieldValidation";
import ButtonMVP from "../ButtonMVP";
import LinkedInLoginButton from "../LinkedInLoginButton";
import LinkedInBtn from "../Signup/LinkedInBtn";
import { loginWithLinkedInSubmit } from "../../utils/linkedin";
import Loading from "../Loading";
import BuilderHeaderBar from "../../components/BuilderHeaderBar";
import { Link } from "react-router-dom";
import HelmetWrapper from "../HelmetWrapper";
import LoginMfa from "../mfa/login_mfa";
import Login2fa from "../twoFactorAuthentication/index";
import { Form as MfaSetup } from "../mfa/mfa_setup";
// import ReCaptcha from "../../components/ReCaptcha";
import Footer from "../../components/Footer";
import alertIcon from "../../../client/images/alert.svg";

const LOGIN_BY_EMAIL_PASSWORD = "LOGIN_BY_EMAIL_PASSWORD";

export default function Login({
  onSubmit,
  loginType: { type, message },
  check2faUser,
  tfaInfo,
  resend2faCode,
  verify2FACode,
  checkLoginMethod,
  ...props
}) {
  const [formData, setFormData] = useState({
    email: props.defaultEmail || "",
    password: "",
    captcha: "",
    captchaInvisible: "",
  });
  const [loading, setLoading] = useState(false);
  const [linkedinError, setlinkedinError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  // const [captchaInvisible, setCaptchaInvisible] = useState(null);
  // const invisibleRecaptchaRef = React.createRef({});

  const handleChange = (key, event) => {
    event.preventDefault();
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData((state) => ({
      ...state,
      [key]: value,
    }));
    const formFields = {
      ...formData,
      [key]: value,
    };
    setFieldErrors(validateFullForm(formFields, fieldErrors, ["email"]));
  };

  useEffect(() => {
    if (props.defaultEmail) {
      setFormData({
        ...formData,
        email: props.defaultEmail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultEmail]);

  useEffect(() => {
    if (!type) {
      setFormData({
        email: props.defaultEmail || "",
        password: "",
        captcha: "",
        captchaInvisible: "",
      });
    }
  }, [type, props.defaultEmail]);

  useEffect(() => {
    if (message === "User not found") {
      addError(
        "email",
        "The email you entered does not match our record. Please try again."
      );
    } else if (message === "Please enter a valid email") {
      addError("email", "The email format you entered is invalid.");
    }
  }, [message]);

  // const triggerInvisibleCaptcha = () => {
  //   invisibleRecaptchaRef.current.reset();
  //   invisibleRecaptchaRef.current.execute();
  // };

  const loginWithEmail = () => {
    return checkLoginMethod(formData);
  };

  // useEffect(() => {
  //   if (captchaInvisible) {
  //     loginWithEmail();
  //     setCaptchaInvisible(null);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [captchaInvisible]);

  // const invisibleCaptchaOnChange = (captchaInvisible) => {
  //   setCaptchaInvisible(captchaInvisible);
  //   if (captchaInvisible) {
  //     setFormData((state) => ({
  //       ...state,
  //       captchaInvisible: captchaInvisible,
  //     }));
  //     return checkLoginMethod(formData);
  //   }
  // };

  // const shouldUseInvisibleCaptcha = (failCount = 0) => {
  //   if (failCount < 3) return true;
  //   return false;
  // };

  // const captchaOnChange = (captcha) => {
  //   setFormData((state) => ({
  //     ...state,
  //     captcha: captcha,
  //   }));
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (type === LOGIN_BY_EMAIL_PASSWORD) {
    //   if (shouldUseInvisibleCaptcha(props.failCount)) {
    //     setCaptchaInvisible(null);
    //     return triggerInvisibleCaptcha();
    //   }
    // }
    return loginWithEmail(formData);
  };

  const linkedInLoginBtnHandler = (callback) => {
    callback();
  };

  const loginWithLinkedin = async (linkedinInfo) => {
    setLoading(true);
    const result = await loginWithLinkedInSubmit({
      ...linkedinInfo,
    });
    setLoading(false);
    if (result.errorMessage) {
      setlinkedinError(result.errorMessage);
    }
    props.onLinkedInLogin(result);
  };

  const hasError = (field) =>
    !isEmpty(fieldErrors[field]) && fieldErrors[`${field}Dirty`];

  const addError = (key, error) => {
    setFieldErrors((state) => ({
      ...state,
      [key]: error,
      [`${key}Dirty`]: true,
    }));
  };

  const onBlur = ({ target }) => {
    const { name: key, value } = target;
    let error;
    if (key === "email") {
      error = validateField(key, value);
    }
    if (!value && key === "password") {
      error = "Password required";
    }
    addError(key, error);
  };

  if (loading) {
    return <Loading />;
  }

  const loginMsg =
    props.circleObj &&
    props.circleObj.circle &&
    props.circleObj.circle.circleName
      ? `Login to ${props.circleObj.circle.circleName}`
      : "Login";
  const faviconURL =
    props.circleObj &&
    props.circleObj.circle &&
    !isEmpty(get(props.circleObj, "circle.faviconURL", ""))
      ? get(props.circleObj, "circle.faviconURL", "")
      : "";
  const circleInfo = get(props, "circleObj.circle", {});
  let content;
  switch (type) {
    case "setup-mfa":
      content = (
        <MfaSetup
          enforceMultifactorAuthentication={true}
          loading={props.loading}
          qrCode={props.mfaPayload.qrCode}
          secret={props.mfaPayload.secret}
          token={props.mfaPayload.token}
          onToken={(tokenInfo) => props.handleMfaToken(tokenInfo, formData)}
          serverError={props.errorMsg}
        />
      );
      break;
    case "check-mfa":
      content = (
        <LoginMfa
          loading={props.loading}
          loginId={formData.email}
          password={formData.password}
          submit={props.submit}
          serverError={props.errorMsg}
        />
      );
      break;
    case "2fa-auth":
      content = (
        <Login2fa
          loading={props.loading}
          loginId={formData.email}
          password={formData.password}
          submit={verify2FACode}
          errorMsg={props.errorMsg}
          tfaInfo={tfaInfo}
          resend2faCode={resend2faCode}
        />
      );
      break;
    default:
    case LOGIN_BY_EMAIL_PASSWORD:
      content = (
        <div>
          <form
            className="login-form"
            data-testid="login-form"
            onSubmit={handleSubmit}
          >
            {props.circleObj &&
              props.circleObj.circle &&
              !get(props.circleObj, "circle.isLynkCircle", false) &&
              !isEmpty(get(props.circleObj, "circle.rectangleLogo", "")) && (
                <BuilderHeaderBar
                  isLynkCircle={
                    !get(props.circleObj, "circle.isLynkCircle", false)
                  }
                  circle={props.circleObj.circle}
                />
              )}
            <div className="headingWrap">
              <header>{loginMsg}</header>
              {false && (
                <div className="wrapper">
                  <LinkedInLoginButton
                    onError={(errorMessage) => setlinkedinError(errorMessage)}
                    callback={(obj) =>
                      loginWithLinkedin({
                        code: obj.code,
                        state: obj.state,
                      })
                    }
                  >
                    {(onClick) => (
                      <LinkedInBtn
                        id="linkedInSignin"
                        text="Log in with LinkedIn"
                        onClick={() => {
                          if (document.activeElement.id !== "linkedInSignin") {
                            return;
                          }
                          linkedInLoginBtnHandler(onClick);
                        }}
                      />
                    )}
                  </LinkedInLoginButton>
                </div>
              )}
              {linkedinError && (
                <div className="errorMessage">
                  <span className="errorIcon fa fa-exclamation-triangle"></span>
                  <span className="errorText">{linkedinError}</span>
                </div>
              )}
              {props.errorMsg &&
                props.errorMsg !== "User not found" &&
                props.errorMsg !== "Please enter a valid email" && (
                  <div className="errorMessage">
                    <img className="errorIcon" src={alertIcon} alt="alert" />
                    <span>{props.errorMsg}</span>
                  </div>
                )}
              <div className="fieldWrap">
                <TextFieldWithLabel
                  label="Email"
                  data-testid="email"
                  className={getErrorClass("email", hasError)}
                  name="email"
                  type="text"
                  value={formData.email}
                  required
                  onChange={(event) => handleChange("email", event)}
                  onBlur={(event) => onBlur(event)}
                />
                {hasError("email", fieldErrors) && (
                  <div className="errorMsg">{fieldErrors.email}</div>
                )}
              </div>
              {type === LOGIN_BY_EMAIL_PASSWORD && (
                <div className="fieldWrap">
                  <span className="linkWrap" style={{ float: "right" }}>
                    <a href="/forgotPassword">Forgot your password?</a>
                  </span>
                  <PasswordFieldWithLabel
                    label="Password"
                    className={getErrorClass("Password", hasError)}
                    name="password"
                    type="password"
                    value={formData.password}
                    required
                    onChange={(event) => handleChange("password", event)}
                    onBlur={(event) => onBlur(event)}
                  />
                  {hasError("password", fieldErrors) && (
                    <div className="errorMsg">{fieldErrors.password}</div>
                  )}
                  {/* <div
                    style={{
                      visibility: `${
                        shouldUseInvisibleCaptcha(props.failCount)
                          ? "visible"
                          : "hidden"
                      }`,
                    }}
                  >
                    <ReCaptcha
                      ref={invisibleRecaptchaRef}
                      sitekey={props.siteKey.invisible}
                      onChange={invisibleCaptchaOnChange}
                      size="invisible"
                      badge="bottomright"
                    />
                  </div>
                  <div
                    style={{
                      visibility: `${
                        shouldUseInvisibleCaptcha(props.failCount)
                          ? "hidden"
                          : "visible"
                      }`,
                      height: `${
                        shouldUseInvisibleCaptcha(props.failCount)
                          ? "0px"
                          : "auto"
                      }`,
                      margin: "12px 0px",
                    }}
                  >
                    <ReCaptcha
                      key="checkboxCaptcha"
                      sitekey={props.siteKey.checkbox}
                      onChange={captchaOnChange}
                    />
                  </div> */}
                </div>
              )}
              <footer>
                <div>
                  <ButtonMVP
                    className="custom-button-widget"
                    id="btnLogin"
                    disabled={
                      !checkNoformErrors(fieldErrors) || isEmpty(formData.email)
                    }
                    onClick={handleSubmit}
                  >
                    {type === LOGIN_BY_EMAIL_PASSWORD ? "Login" : "Proceed"}
                  </ButtonMVP>
                </div>
              </footer>
            </div>
          </form>
          <div>
            {false &&
              (!get(circleInfo, "circleCode") ||
                isEmpty(circleInfo) ||
                circleInfo.circleCode === "lynk" ||
                get(circleInfo, "isSelfSignupAllowed", false)) && (
                <div className="signupLink">
                  Don't have an account?
                  <Link
                    to={`${
                      circleInfo.circleCode && circleInfo.circleCode !== "lynk"
                        ? `/circle/${circleInfo.circleCode}/`
                        : "/"
                    }signup`}
                  >
                    &nbsp;Create an account
                  </Link>
                </div>
              )}
          </div>
        </div>
      );
  }
  return (
    <div className="pageWrapper">
      <HelmetWrapper title="Login" faviconURL={faviconURL}>
        {content}
      </HelmetWrapper>
      <Footer />
    </div>
  );
}
