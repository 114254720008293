import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { clearCookiee } from "../../api/lynkClient";
export default function Logout({ setIsAuthenticated }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const goToLogin = (search) => navigate(`/login${search}`);

  useEffect(() => {
    clearCookiee()
      .then((response) => {
        if (response && response.data.success)
          console.log("Cookiee cleared successfully");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsAuthenticated(false);
        goToLogin(search);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>Logging out.</div>;
}
