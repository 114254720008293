import React, { useState } from "react";
import LoginMagicLynkWidget from "../../components/LoginMagicLynk";
import "./loginMagicLynk.scss";
import { loginUser } from "../../api/lynkClient";
import { useLocation, useNavigate } from "react-router-dom";

export default function LoginMagicLynk(props) {
  const navigate = useNavigate();
  const search = useLocation().search;
  const [errorMsg, setErrorMsg] = useState("");
  const handleUserLogin = ({ email, password, captchaInvisible }) => {
    setErrorMsg("");
    loginUser(email, password, null, captchaInvisible, null)
      .then((response) => {
        props.setIsAuthenticated(true);
        setErrorMsg("");
        navigate(`/redirect${search}`);
      })
      .catch(() => {
        setErrorMsg("Email or password is incorrect, please try again");
      });
  };
  const handleLinkedInUserLogin = (result) => {
    if (result.success) {
      props.setIsAuthenticated(true);
      navigate(`/redirect${search}`);
    } else {
      props.setIsAuthenticated(false);
    }
  };

  return (
    <div className="magic-lynk-login-page" data-testid="login-magic-link">
      <LoginMagicLynkWidget
        onLinkedInLogin={handleLinkedInUserLogin}
        data={props.magicCodeFormData}
        onSubmit={handleUserLogin}
        errorMsg={errorMsg}
        siteKey={props.siteKey}
      />
    </div>
  );
}
