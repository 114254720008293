/**
 *
 * FooterCopy
 *
 */

import React from "react";
import styles from "./styles.module.scss";

function FooterCopy({ height }) { // eslint-disable-line
  return (
    <div
      className={styles.copytext}
      style={{
        lineHeight: height + "px",
      }}
    >
      © {new Date().getFullYear()}, TSN Holdings. All Rights Reserved.
    </div>
  );
}

export default FooterCopy;
