import React, { useEffect, useState } from "react";
// import { FormattedMessage } from "react-intl";
// import PropTypes from "prop-types";
import Button from "../../ButtonMVP";
import Input from "../../Login/Input";
import "./style.css";

function Setup(props) {
  // Future reference
  // const propTypes = {
  //   currentUser: PropTypes.object,
  //   siteName: PropTypes.string,
  //   enforceMultifactorAuthentication: PropTypes.bool.isRequired,
  //   actions: PropTypes.shape({
  //     activateMfa: PropTypes.func.isRequired,
  //     generateMfaSecret: PropTypes.func.isRequired,
  //   }).isRequired,
  //   // provide from login page/ signup page
  //   serverError: PropTypes.string,
  //   loading: PropTypes.bool,
  // };

  const [secret, setSecret] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [token, setToken] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    // qrCode provide if render from login page
    if (!props.qrCode) {
      console.log(`qr code didn't exist`);
      //   generateMfaSecret()
      //     .then(({ data: { secret, qrCode, token } = {} }) => {
      //       this.setState({ secret, qrCode, token });
      //       setSecret(secret);
      //       setQrCode(qrCode);
      //       setToken(token);
      //     })
      //     .catch((error) => {
      //       setError(error.message);
      //     });
    } else {
      const { secret, qrCode, token } = props;
      setSecret(secret);
      setQrCode(qrCode);
      setToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (e) => {
    e.preventDefault();
    if (!code || code.length === 0) {
      setError(
        // Utils.localizeMessage(
        //     'lynk.mfa.setup.codeError',
        "Please enter the code from Google Authenticator."
        // )
      );
      return;
    }

    setError(null);
    // used by login, signup
    if (props.onToken) return props.onToken({ mfaToken: code, token: token });
    props.actions
      .activateMfa({ mfaToken: code, token: token })
      //   .then(props.actions.getMe)
      .then(() => {
        this.props.history.push("/mfa/confirm");
      })
      .catch((error) => {
        if (
          error.server_error_id === "ent.mfa.activate.authenticate.app_error"
        ) {
          return setError(
            "Invalid code. If this issue persists, contact your System Administrator."
          );
        }
        setError(
          error.message === "INVALID_TOKEN" ? "Invalid Token" : error.message
        );
      });
  };

  let formClass = "form-group";
  let errorContent;
  let errorMsg = error || props.serverError;
  if (errorMsg) {
    errorContent = (
      <div className="form-group has-error">
        <label className="control-label">{errorMsg}</label>
      </div>
    );
    formClass += " has-error";
  }

  return (
    <div
      className="signup__email-container mfa-setup"
      style={{ width: "100%" }}
    >
      <div
        style={{ fontSize: 34, color: "#99A6A5", lineHeight: "normal" }}
        className="color--light"
      >
        Two-Factor Authentication
      </div>
      <form onSubmit={submit} className={formClass}>
        <ol>
          <li>
            Download a two-factor authentication app on your mobile device (such
            as Google Authenticator on the{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
            >
              iTunes
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
            >
              Google Play
            </a>{" "}
            store, or one encouraged by your organization).
          </li>
          <li>
            Open the app, then scan the QR code below or manually enter this
            code:
            <strong>
              {/* <FormattedMessage
                id="lynk.mfa.setup.secret"
                defaultMessage="{secret}"
                values={{
                  secret: secret,
                }}
              /> */}
              {secret}
            </strong>
            <div>
              <img alt={"qr code"} style={style.qrCode} src={qrCode} />
            </div>
          </li>
          <li>
            <div>
              Enter the current six-digit numerical passcode from the two-factor
              authenticator app to verify your device is properly configured.
              {/* <FormattedMessage
                id="lynk.mfa.setup.step3"
                defaultMessage=""
              /> */}
            </div>
            <br />
            <div>
              <Input
                label="Authenticator Code *"
                className="form-control"
                onChange={(e) => {
                  setCode(e.currentTarget.value.replace(/\s/g, ""));
                }}
                placeholder={"MFA Code"}
                autoFocus={true}
              />
              {errorContent}
            </div>
          </li>
        </ol>

        <Button
          loading={props.loading}
          style={{ margin: "16px 45px 16px 0", width: 150 }}
          btnType="success"
          type="submit"
          className="btn btn-primary"
        >
          Enable
          {/* <FormattedMessage id="lynk.mfa.setup.save" defaultMessage="Enable" /> */}
        </Button>

        {!props.enforceMultifactorAuthentication && (
          //   <a onClick={redirectUserToDefaultTeam} style={{ color: "#71797D" }}>
          <a href="/#" style={{ color: "#71797D" }}>
            I will set up 2FA later
          </a>
        )}
      </form>
    </div>
  );
}

export default Setup;
const style = {
  qrCode: { maxHeight: 100 },
};
