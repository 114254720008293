// import g from "glamorous";
import colors from "../assets/style/sass/_lynk-colors.scss";
const descriptionClass = {
  fontSize: 13,
  lineHeight: "18px",
  color: colors.neutral_500_base,
  "& a": {
    color: colors.neutral_800,
  },
};

const Description = <p className={descriptionClass}></p>;
export default Description;
