import React from "react";
// import g from "glamorous";
import Description from "../descriptionText";
import colors from "../../assets/style/sass/_lynk-colors.scss";
const inputStyle = {
  background: colors.white,
  borderRadius: 2,
  // padding: 16,
  boxShadow: "none",
  border: `1px solid ${colors.neutral_100}`,
  color: "#7B8994",
};

// const Crtl = g.div({
//     margin: '8px 0 ',
// });

export default function Input({ label, disabled, description, ...rest }) {
  return (
    <div style={{ pointerEvents: disabled ? "none" : "auto", opacity: 1 }}>
      <label htmlFor={`form_${label || rest.placeholder}`}>
        {label || rest.placeholder}
      </label>
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
      <input
        className={inputStyle}
        id={`form_${label || rest.placeholder}`}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
}
