import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import MagicLynkWidget from "../../components/MagicLynk";
import HelmetWrapper from "../../components/HelmetWrapper";
import ErrorBoundary from "../../containers/ErrorBoundary";
import MagicLynkErrorWidget from "../../components/MagicLynkError";
import _ from "lodash";
import { validateMagicCode } from "../../api/lynkClient";
import Loading from "../../components/Loading";
import "./magicLynk.scss";
import { getScreen } from "./utils";
import { SETUP, WELCOME } from "../../utils/constants";

export default function MagicLynk(props) {
  const [iskPExists, setKPExists] = useState(false);
  const [magicCodeFormData, setMagicCodeData] = useState({});
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const magicCode = new URLSearchParams(search).get("c");
  // need to track these events later to mixpanel
  // const type = new URLSearchParams(search).get("type");
  // if (type === "user-resend-invite") {
  //   this.props.trackEvent({ type: "Self sign up - open magic link invite" });
  // }
  if (_.isEmpty(magicCode)) {
    setLoading(false);
    window.location.href = `/signup`;
  }

  useEffect(() => {
    if (!_.isEmpty(magicCode)) {
      validateMagicCode(magicCode)
        .then((response) => {
          setLoading(true);
          setMagicCodeData(response.data);
          setKPExists(_.get(response, "existingKP", false));
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            setMagicCodeData({});
            throw Error(err.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [magicCode, search]);

  if (loading) return <Loading />;

  const renderStep = (screen, title, faviconURL) => {
    const stepTitle = title ? title : "Magic Craftt Signup";
    return (
      <HelmetWrapper title={stepTitle} faviconURL={faviconURL}>
        <ErrorBoundary>{screen}</ErrorBoundary>
      </HelmetWrapper>
    );
  };
  const getRenderedComponent = () => {
    if (
      _.isEmpty(magicCode) ||
      (magicCodeFormData && magicCodeFormData.found)
    ) {
      const stepData = getScreen(
        _.isEmpty(magicCode) ? SETUP : WELCOME,
        magicCodeFormData,
        iskPExists,
        props
      );
      return renderStep(
        stepData.screen,
        stepData.title,
        _.get(magicCodeFormData, "faviconURL")
      );
    }
    if (magicCodeFormData && !magicCodeFormData.found && !iskPExists) {
      return renderStep(
        <MagicLynkErrorWidget magicCodeError={magicCodeFormData} />
      );
    }
    return renderStep(<Loading />);
  };

  return <div className="page-center-align">{getRenderedComponent()}</div>;
}
