import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MIN_USERNAME_LENGTH,
  MAX_USERNAME_LENGTH,
  helperText,
} from "../../../utils/constants";
import * as Utils from "../../../utils";
import { validateEmail } from "../../../utils/emailValidation";
import LoadingScreen from "../../../components/Loading";
import Refresh from "../../../components/Refresh";
// import Description from "../../../components/descriptionText";
import Button from "../../../components/ButtonMVP";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import PasswordFieldWithLabel from "../../../components/PasswordFieldWithLabel";
import {
  // resolveEmailInvite,
  loginAndSetupMfa,
  getInviteInfo as getUserInviteInfo,
  createUserWithInvite,
  loginUser,
} from "../../../api/lynkClient";
import * as _ from "lodash";
import { Form as MfaSetup } from "../../../components/mfa/mfa_setup";
import colors from "../../../assets/style/sass/_lynk-colors.scss";
import { Link } from "react-router-dom";
import "./index.scss";
import crafttLogo from "../../../images/signup/craftt-neutral-logo.svg";
export default function SignupEmail(props) {
  const [suggestName, setSuggestedName] = useState("");
  const [mfaPayload, setMfaPayload] = useState({});
  const [user, setUser] = useState({});
  const [showMfaSetup, setShowMfaSetup] = useState(false);
  const [signupSuccessData, setSignupSuccessData] = useState({});
  const [loading, setLoading] = useState(false);
  // const [editedName, setEditedName] = useState(false);
  const [data, setData] = useState();
  const [hash, setHash] = useState();
  // const [teamDisplayName, setTeamDisplayName] = useState();
  // const [teamId, setTeamId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [inviteId, setInviteId] = useState("");
  const [serverError, setServerError] = useState("");
  const [noOpenServerError, setNoOpenServerError] = useState("");
  const [username, setUsername] = useState("");
  // const [preJoinChannelIds, setPreJoinChannelIds] = useState([]);
  // const [parsedData, setParsedData] = useState(null);
  const [canEditEmailAndUserName, setCanEditEmailAndUserName] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cpasswordError, setCPasswordError] = useState("");
  const { search } = useLocation();

  const navigate = useNavigate();
  const getInviteInfo = (search) => {
    let data = new URLSearchParams(search).get("d");
    let hash = new URLSearchParams(search).get("h");
    const inviteId = new URLSearchParams(search).get("id");
    let email = "";
    // let teamDisplayName = "";
    let teamName = "";
    // let teamId = "";
    let loading = false;
    const serverError = "";
    const noOpenServerError = false;

    // lynk vars
    let username = "";
    // let preJoinChannelIds = [];
    let parsedData = null;

    if (hash && hash.length > 0) {
      parsedData = JSON.parse(data);
      email = parsedData.email;
      //set suggestedName
      // teamDisplayName = parsedData.display_name;
      teamName = parsedData.name;
      // teamId = parsedData.id;
      setTeamName(teamName);
      // lynk used
      username = parsedData.username;
      setSuggestedName(username);
      // preJoinChannelIds = (parsedData.channelIds || "")
      //   .split(",")
      //   .filter(Boolean);
    } else if (inviteId && inviteId.length > 0) {
      loading = true;
      getUserInviteInfo(
        inviteId,
        (inviteData) => {
          if (!inviteData) {
            setLoading(false);
            return;
          }

          setLoading(false);
          setServerError("");
          // setTeamDisplayName(inviteData.display_name);
          setTeamName(inviteData.name);
          // setTeamId(inviteData.id);
        },
        () => {
          setLoading(false);
          setNoOpenServerError(true);
          setServerError(
            "The invite link was invalid.  Please speak with your Administrator to receive an invitation."
          );
        }
      );

      data = null;
      hash = null;
    }
    setData(data);
    setHash(hash);
    setEmail(email);
    // setTeamDisplayName(teamDisplayName);
    // setTeamId(teamId);
    setInviteId(inviteId);
    setLoading(loading);
    setServerError(serverError);
    setNoOpenServerError(noOpenServerError);
    setUsername(username);
    // setPreJoinChannelIds(preJoinChannelIds);
    // setParsedData(parsedData);
    setCanEditEmailAndUserName(!username && !email && !data);
  };

  useEffect(() => {
    getInviteInfo(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignupSuccess = (userResponse) => {
    setUser(userResponse.data);
    setSignupSuccessData(userResponse.data);
    setIsSubmitting(false);

    login(email, password, "")
      .then((loginResponse) => postLogin(loginResponse, data))
      .catch(postLoginFail);
  };

  const login = (loginId, password, token) => {
    setServerError(null);
    setLoading(true);
    return loginUser(loginId, password, null, null, null, token);
  };

  const postLogin = async (data) => {
    // if (hash > 0) {
    // BrowserStore.setGlobalItem(
    //   hash,
    //   JSON.stringify({ usedBefore: true })
    // );
    // }

    // try {
    // notify CPB that this token used
    // if (parsedData && teamId) {
    //   await resolveEmailInvite(teamId, parsedData);
    // }
    // join channel automatically if channels provided by CPB
    // if (preJoinChannelIds && preJoinChannelIds.length > 0) {
    //   const allJoinRequest = preJoinChannelIds.map((channelId) => {
    // return props.actions.joinChannel(
    //   data.id,
    //   teamId,
    //   channelId
    // );
    // });
    // await Promise.all(allJoinRequest);
    // }
    // } catch (error) {
    // setServerError(error.message);
    setIsSubmitting(false);
    // }

    // loadMe().then(() => {
    //   const redirectTo = new URLSearchParams(props.location.search).get(
    //     "redirect_to"
    //   );
    //   if (redirectTo) {
    //     browserHistory.push(redirectTo);
    //   } else {
    //     // GlobalActions.redirectUserToDefaultTeam();
    //     //redirect to default Team
    //   }
    // });
    props.setIsAuthenticated(true);
    navigate(
      `/redirect?service=CIRCLE_CRAFTT&redirect_to=/${teamName}/search/experts`
    );
  };

  const postLoginFail = (err) => {
    const errResponse = err && err.response && err.response.data;
    if (
      errResponse &&
      errResponse.action &&
      errResponse.action.type === "MFA_SETUP"
    ) {
      // never setup
      if (errResponse.action.payload && errResponse.action.payload.qrCode) {
        setShowMfaSetup(true);
        setMfaPayload(errResponse.action.payload);
        setLoading(false);
      } else {
        setLoading(false);
        setServerError("We faced problem when login");
      }
    } else {
      setServerError(
        errResponse.message === "INVALID_TOKEN"
          ? "Invalid Token"
          : errResponse.message
      );
      setLoading(false);
    }
  };

  const handleMfaToken = async ({ mfaToken, token }) => {
    const { email } = user;
    return loginAndSetupMfa(email, password, mfaToken, token).then(() => {
      postLogin(signupSuccessData);
    }, postLoginFail);
  };

  const isUserValid = () => {
    let isValid = true;
    setEmailError("");
    setNameError("");
    setPasswordError("");
    setCPasswordError("");
    setServerError("");
    const providedEmail = email && email.trim();
    if (!providedEmail) {
      setEmailError("This field is required");
      isValid = false;
    }

    if (!validateEmail(providedEmail)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    }
    const providedUsername = suggestName && suggestName.trim().toLowerCase();
    if (!providedUsername) {
      setNameError("This field is required");
      isValid = false;
    }

    const usernameError = Utils.isValidUsername(providedUsername);
    if (usernameError === "Cannot use a reserved word as a username.") {
      setNameError("This username is reserved, please choose a new one.");
      isValid = false;
    } else if (usernameError) {
      setNameError(
        `Username must begin with a letter, and contain between ${MIN_USERNAME_LENGTH} to ${MAX_USERNAME_LENGTH} lowercase characters made up of numbers, letters, and the symbols '.', '-' and '_'.`
      );
      isValid = false;
    }

    const providedPassword = password;
    // const PS_POLICY = `Password should be unique with at least 12 characters, and containing at least one uppercase letter, one special character, and one number. If you suspect that your password has been leaked, please change your password immediately and contact <a href="mailto:security@circle.craftt.co">security@circle.craftt.co</a>.`;
    const PS_ERROR = "Your password does not conform to security policy";
    const MIN_PASSWORD_LENGTH = 3;
    const passwordError = Utils.isValidPassword(
      providedPassword,
      MIN_PASSWORD_LENGTH,
      PS_ERROR
    );
    if (passwordError) {
      setPasswordError(PS_ERROR);
      isValid = false;
    }

    if (providedPassword !== confirmPassword) {
      setCPasswordError(
        "Your password and confirmation password do not match."
      );
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // bail out if a submission is already in progress
    if (isSubmitting) {
      return;
    }
    if (!isUserValid()) {
      return;
    }

    setNameError("");
    setEmailError("");
    setPasswordError("");
    setCPasswordError("");
    setServerError("");
    setIsSubmitting(true);

    const user = {
      email: email.trim(),
      username: suggestName && suggestName.trim().toLowerCase(),
      first_name: firstName ? firstName.trim() : "",
      last_name: lastName ? lastName.trim() : "",
      password,
      allow_marketing: true,
    };
    createUserWithInvite(
      {
        user,
        data,
        emailHash: hash,
        inviteId,
      },
      handleSignupSuccess,
      (err) => {
        const errMsg = _.get(err, "response.data.message", "") || err.message;
        setServerError(errMsg);
        setIsSubmitting(false);
      }
    );
  };

  const handleEmailChange = (e) => {
    const value = e.target.value.split("@")[0];
    if (!value) return setSuggestedName("");
    const suggestName = value.replace(/[\W\s]/gi, "");
    setEmail(e.target.value);
    if (suggestName) {
      setSuggestedName(suggestName);
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const renderEmailSignup = () => {
    let emailHelpText = (
      <span className="help-block">"Valid email required for sign-up"</span>
    );
    let emailDivStyle = "form-group";
    if (emailError) {
      emailHelpText = "";
      emailDivStyle += " has-error";
    }

    // let nameHelpText = (
    //   <span className="help-block">
    //     {`Username must begin with a letter, and contain between ${MIN_USERNAME_LENGTH} to ${MAX_USERNAME_LENGTH} lowercase characters made up of numbers, letters, and the symbols '.', '-' and '_'`}
    //   </span>
    // );
    let nameDivStyle = "form-group";
    if (nameError) {
      // nameHelpText = nameError;
      nameDivStyle += " has-error";
    }

    let passwordDivStyle = "form-group";
    if (passwordError) {
      passwordDivStyle += " has-error";
    }
    if (cpasswordError) {
      passwordDivStyle += " has-error";
    }

    // let yourEmailIs = null;
    // if (email) {
    //   yourEmailIs = (
    //     <FormattedHTMLMessage
    //       id="signup_user_completed.emailIs"
    //       defaultMessage="Your email address is <strong>{email}</strong>. You'll use this address to sign in to {siteName}."
    //       values={{
    //         email: email,
    //         siteName: props.siteName,
    //       }}
    //     />
    //   );
    // }

    // let emailContainerStyle = "margin--extra";

    let btnContent = "Create Account";
    // (
    // <FormattedMessage
    //   id="signup_user_completed.create"
    //   defaultMessage="Create Account"
    // />
    // );
    if (isSubmitting) {
      btnContent = (
        <span>
          <Refresh />
        </span>
      );
    }

    return (
      <form>
        <div className="inner__content">
          <div className={emailDivStyle + ` input-form-align`}>
            <TextFieldWithLabel
              id="email"
              type="text"
              value={email}
              className="form-control"
              defaultValue={email}
              label="Email Address"
              disabled={!canEditEmailAndUserName}
              onChange={handleEmailChange}
              spellCheck="false"
              autoCapitalize="off"
              required={true}
            />
            {emailError}
            {emailHelpText && false}
          </div>
          <div className={nameDivStyle + ` input-form-align`}>
            <TextFieldWithLabel
              id="name"
              type="text"
              // ref='name'
              innerRef={(r) => {
                this.name = r;
              }}
              className="form-control"
              label="Username"
              value={canEditEmailAndUserName ? suggestName : username}
              disabled={!canEditEmailAndUserName}
              onChange={(e) => {
                if (canEditEmailAndUserName) {
                  setSuggestedName(e.target.value);
                }
              }}
              maxLength={MAX_USERNAME_LENGTH}
              spellCheck="false"
              autoCapitalize="off"
              required={true}
            />
            {nameError}
          </div>
          <div className={nameDivStyle + ` input-form-align`}>
            <TextFieldWithLabel
              id="firstName"
              type="text"
              value={firstName}
              className="form-control"
              defaultValue={firstName}
              label="First Name"
              onChange={handleFirstNameChange}
              spellCheck="false"
              autoCapitalize="off"
            />
          </div>
          <div className={nameDivStyle + ` input-form-align`}>
            <TextFieldWithLabel
              id="lastName"
              type="text"
              value={lastName}
              className="form-control"
              defaultValue={lastName}
              label="Last Name"
              onChange={handleLastNameChange}
              spellCheck="false"
              autoCapitalize="off"
            />
          </div>
          <div className={passwordDivStyle + ` input-form-align`}>
            <PasswordFieldWithLabel
              id="password"
              type="password"
              className="form-control"
              label="Password"
              helperText={helperText}
              maxLength="128"
              autoFocus
              spellCheck="false"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {passwordError}
          </div>
          <div className={passwordDivStyle + ` input-form-align`}>
            <PasswordFieldWithLabel
              id="cpassword"
              type="password"
              name="cpassword"
              className="form-control"
              label="Confirm Password"
              maxLength="128"
              spellCheck="false"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {cpasswordError}
          </div>

          <p className="margin--extra">
            <Button
              btnType="success"
              id="createAccountButton"
              type="submit"
              onClick={handleSubmit}
              className="btn-primary btn"
              style={{ width: "100%" }}
              disabled={isSubmitting}
            >
              {btnContent}
            </Button>
          </p>
        </div>
      </form>
    );
  };

  let serverErrorUI = null;
  if (serverError) {
    serverErrorUI = (
      <div className="form-group has-error">
        <label className="control-label text-danger">{serverError}</label>
      </div>
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  let emailSignup;
  if (props.enableSignUpWithEmail) {
    emailSignup = renderEmailSignup();
  } else {
    return null;
  }

  if (showMfaSetup) {
    emailSignup = (
      <div style={{ marginTop: 64 }}>
        <MfaSetup
          qrCode={mfaPayload.qrCode}
          secret={mfaPayload.secret}
          token={mfaPayload.token}
          onToken={handleMfaToken}
          serverError={serverError}
          loading={loading}
        />
      </div>
    );
  }

  if (noOpenServerError) {
    emailSignup = null;
  }

  return (
    <div className="signup-page signup-team__wrapper">
      <div style={{ display: "flex", height: "96%" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "unset",
          }}
        >
          <div className="signup-team__container ">
            {!showMfaSetup && (
              <div
                className="signup-header-title"
                style={{ color: colors.neutral_800 }}
              >
                Sign Up
              </div>
            )}
            {emailSignup}
            {/* error msg delegated into MFA setup component */}
            {!showMfaSetup && serverErrorUI}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "2px",
            }}
          >
            <p style={{ paddingRight: "4px" }}>Already have an account?</p>
            <Link
              to={"/"}
              style={{
                color: colors.neutral_800,
                textDecoration: "underline",
                fontWeight: 600,
              }}
            >
              Login
            </Link>
          </div>
        </div>
      </div>
      <div className="signup-footer">
        <img className="craftt-logo" src={crafttLogo} alt="Craftt" />
      </div>
    </div>
  );
}
