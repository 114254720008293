import React from "react";
import { isEmpty } from "lodash";
import styles from "./styles.module.scss";
import { generateRectangleLogo } from "../../containers/Onboarding/utils";

const ContentColumn = (props) => {
  const { circle } = props;
  const isPN = !isEmpty(circle) && !circle.isLynkCircle;

  return (
    <React.Fragment>
      <div className={styles.contentWrap}>
        <div className={styles.imageHeader}>
          {isPN && !isEmpty(circle.rectangleLogo) && (
            <div className={styles.lynkBrand}>
              {" "}
              <img
                alt="lynk"
                height="20"
                src={generateRectangleLogo(circle.rectangleLogo)}
              />
            </div>
          )}
        </div>

        {props.children}
      </div>
    </React.Fragment>
  );
};

export default ContentColumn;
