/** @format */

import { useState, useEffect } from "react";
import styles from "./resetPassword.module.scss";
import Loading from "../../components/Loading";
import HelmetWrapper from "../../components/HelmetWrapper";
import React from "react";
import ContentColumn from "../../components/ContentColumn";
import Footer from "../../components/Footer";
import ButtonMVP from "../../components/ButtonMVP";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import PasswordFieldWithLabel from "../../components/PasswordFieldWithLabel";
import {
  addError,
  checkNoFieldErrors,
  getErrorClass,
  validateField,
} from "../../utils/signupHelper";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { validateMagicCode, resetPassword } from "../../api/lynkClient";

export default function ResetPassword({ setIsAuthenticated }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState({
    userEmail: "",
    userPassword: "",
    userConfirmPassword: "",
  });
  const [invalidLink, setInvalidLink] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();
  const search = useLocation().search;
  const magicCode = new URLSearchParams(search).get("c");

  const apiErrorToStateUpdate = (AxiosError) => {
    console.log(AxiosError);
    setErrorMessage("Something went wrong");
    setInvalidLink(true);
    setLoading(false);
  };

  useEffect(() => {
    if (!isEmpty(magicCode)) {
      validateMagicCode(magicCode)
        .then((result) => {
          if (result.data.found) {
            setFormData({ userEmail: result.data.email });
            setInvalidLink(false);
          } else {
            setFormData({ userEmail: "" });
            setInvalidLink(true);
          }
        })
        .catch(apiErrorToStateUpdate)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [magicCode, search]);

  const validateFullForm = (values) => {
    let error = {};
    const fullError = formErrors;
    const fields = ["userEmail", "userPassword", "userConfirmPassword"];
    fields.forEach((field) => {
      if (field === "userConfirmPassword") {
        error = validateField(field, {
          userPassword: values.userPassword,
          userConfirmPassword: values.userConfirmPassword,
        });
      } else {
        error = validateField(field, values[field]);
      }
      fullError[field] = error;
    });
    return fullError;
  };

  const useFormValidation = () => {
    useEffect(() => {
      const fieldErrors = validateFullForm(formData);
      setFormErrors({ ...fieldErrors });
    }, []);
  };

  useFormValidation();

  const loginAndGoToProfile = () => {
    setIsAuthenticated(true);
    navigate(`/redirect`);
  };

  const goToLogin = () => navigate(`/login`);

  const hasError = (field) =>
    !isEmpty(formErrors[field]) && formErrors[`${field}Dirty`];

  const onBlur = ({ target }) => {
    let error;
    const { userPassword, userConfirmPassword } = formData;
    if (target.name === "userConfirmPassword") {
      error = validateField(target.name, { userPassword, userConfirmPassword });
    } else {
      error = validateField(target.name, target.value);
    }
    const fieldErrors = addError(formErrors, target.name, error);
    setFormErrors(fieldErrors);
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
    const formFields = {
      ...formData,
      [name]: value,
    };
    const fieldErrors = validateFullForm(formFields);
    setFormErrors(fieldErrors);
  };

  const onSubmit = () => {
    const magicCode = new URLSearchParams(search).get("c");
    const payload = {
      registrationEmail: formData.userEmail,
      password: formData.userPassword,
      magicCode,
    };
    setLoading(true);
    resetPassword(payload)
      .then((result) => {
        setShowSuccess(true);
      })
      .catch(apiErrorToStateUpdate)
      .finally(() => {
        setLoading(false);
      });
  };

  const renderSuccess = () => (
    <div className={styles.contentWrapper}>
      <h1>You have successfully changed your password.</h1>
      <div className={styles.btnWrap}>
        <ButtonMVP
          className="wide"
          id="reset-password"
          onClick={loginAndGoToProfile}
        >
          Back to my profile
        </ButtonMVP>
      </div>
    </div>
  );

  const renderFailure = () => (
    <div className={styles.contentWrapper}>
      <h1>The reset password link has expired or is invalid</h1>
      <div className={styles.btnWrap}>
        <ButtonMVP className="wide" id="reset-password" onClick={goToLogin}>
          Back to Log in
        </ButtonMVP>
      </div>
    </div>
  );

  const renderForm = () => {
    return (
      <div className={styles.contentWrapper}>
        <h1>Reset your password</h1>
        <div className={styles.formBody}>
          {errorMessage && (
            <div className={styles.errorMessage}>
              <span
                className={`${styles.errorIcon} fa fa-exclamation-triangle`}
              ></span>
              <span className={styles.errorText}>{errorMessage}</span>
            </div>
          )}
          <div className={styles.fieldWrap}>
            <TextFieldWithLabel
              disabled
              className={getErrorClass("userEmail", hasError)}
              label="Email"
              value={formData.userEmail}
              name="userEmail"
              type="text"
              required
              onChange={(event) => handleOnChange(event)}
              onBlur={(event) => onBlur(event)}
            />
            {hasError("userEmail") && (
              <div className={styles.errorMsg}>{formErrors.userEmail}</div>
            )}
          </div>
          <div className={styles.fieldWrap}>
            <PasswordFieldWithLabel
              label="Create Password"
              className={getErrorClass("userPassword", hasError)}
              value={formData.userPassword}
              name="userPassword"
              type="password"
              required
              onChange={(event) => handleOnChange(event)}
              onBlur={(event) => onBlur(event)}
            />
            {hasError("userPassword") && (
              <div className={styles.errorMsg}>{formErrors.userPassword}</div>
            )}
          </div>
          <div className={styles.fieldWrap}>
            <PasswordFieldWithLabel
              label="Confirm Password"
              className={getErrorClass("userConfirmPassword", hasError)}
              value={formData.userConfirmPassword}
              name="userConfirmPassword"
              type="password"
              required
              onChange={(event) => handleOnChange(event)}
              onBlur={(event) => onBlur(event)}
            />
            {hasError("userConfirmPassword") && (
              <div className={styles.errorMsg}>
                {formErrors.userConfirmPassword}
              </div>
            )}
          </div>
          <div className={styles.btnWrap}>
            <ButtonMVP
              disabled={!checkNoFieldErrors(formErrors)}
              className="wide"
              id="reset-password"
              onClick={onSubmit}
            >
              Reset password
            </ButtonMVP>
          </div>
        </div>
      </div>
    );
  };

  const renderNextScreen = () => {
    if (showSuccess) {
      return renderSuccess();
    }
    if (invalidLink) {
      return renderFailure();
    }
    return renderForm();
  };

  if (loading) return <Loading />;
  return (
    <div
      className={styles["resetPwd-page"]}
      id="resetPwd-form"
      data-testid="resetPwd-form"
    >
      <React.Fragment>
        <HelmetWrapper title="Forgot Password">
          <ContentColumn showLogo={false}>{renderNextScreen()}</ContentColumn>
        </HelmetWrapper>
        <Footer />
      </React.Fragment>
    </div>
  );
}
