import React, { useState } from "react";
import SignupMagicLynkWidget from "../../components/SignupMagicLink";
import "./styles.module.scss";
import { createAccount } from "../../api/lynkClient";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";

export default function SignupMagicLink({
  setIsAuthenticated,
  magicCodeFormData,
}) {
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const search = useLocation().search;
  const handleCreateUserAccount = ({ email, password, magicCode }) => {
    const fieldsData = {
      email,
      password,
      magicCode,
      saveShortTnC: true,
      magicLink: true,
      linkedin: true,
    };
    createAccount(fieldsData)
      .then((response) => {
        if (get(response, "data.success", false)) {
          setIsAuthenticated(true);
          navigate(`/redirect${search}`);
        } else setErrorMsg(response.data.message);
      })
      .catch((err) => console.error(err));
  };

  const handleLinkedInUserLogin = (result) => {
    if (result.success) {
      setIsAuthenticated(true);
      navigate(`/redirect${search}`);
    } else {
      setIsAuthenticated(false);
    }
  };

  return (
    <div className="magic-lynk-login-page">
      <SignupMagicLynkWidget
        data={magicCodeFormData}
        onSubmit={handleCreateUserAccount}
        setIsAuthenticated={setIsAuthenticated}
        errorMsg={errorMsg}
        onLinkedInLogin={handleLinkedInUserLogin}
      />
    </div>
  );
}
