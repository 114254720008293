export const WELCOME = 0;
export const SETUP = 1;
export const PERSONAL = 2;

export const FIELD_NAMES = {
  LoginMagicLynk: {
    EMAIL: "email",
    PASSWORD: "password",
    CONFIRMPASSWORD: "confirmPassword",
    BASICTNC: "basicTnc",
  },
  LoginMagicLynkError: {
    EMAIL: "The email format you entered is invalid.",
    PASSWORD: "Password required",
    CONFIRMPASSWORD: "Passwords do not match",
    BASICTNC: "Please accept TnC",
    PASSWORD_POLICY:
      "Password must be at least 12 characters, and contain at least one uppercase letter, one special character, and one number",
  },
};

export const VALIDATION_FIELDS = {
  LoginMagicLynk: ["email", "password"],
  SignUpMagicLynk: ["email", "password", "confirmPassword", "basicTnc"],
};

export const helperText =
  "Password should be unique with at least 12 characters, and containing at least one uppercase letter, one special character, and one number.";
export const MIN_USERNAME_LENGTH = 3;
export const MAX_USERNAME_LENGTH = 22;
export const MIN_PASSWORD_LENGTH = 12;
export const MAX_PASSWORD_LENGTH = 64;
export const RESERVED_USERNAMES = [
  "valet",
  "all",
  "channel",
  "here",
  "matterbot",
];
