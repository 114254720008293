import React, { Component } from "react";
import "./styles.css";
import close from "../../../images/close-white.png";

class LinkedInTncModal extends Component {
  render() {
    const { title, isOpen } = { ...this.props };
    const modalStyle = isOpen ? "show" : "hide";
    return (
      <div className={`modalOverlay ${modalStyle}`}>
        <div className="modal">
          <div className="modalHeader">
            {title}
            <button className="modalClose" onClick={this.props.closeModal}>
              <img src={close} alt="close" />
            </button>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default LinkedInTncModal;
