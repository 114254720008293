/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const TextFieldWithLabel = ({
  name,
  type,
  label,
  required,
  value,
  onChange,
  onBlur,
  className,
  disabled,
  onKeyUp,
}) => (
  <div className={styles.fieldContainer}>
    <label htmlFor={name}>
      {label}
      {required && <span className={styles.required}>*</span>}
    </label>
    <input
      onKeyUp={onKeyUp}
      disabled={disabled}
      autoComplete="off"
      type={type}
      id={name}
      className={styles[className]}
      name={name}
      data-testid={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  </div>
);

TextFieldWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextFieldWithLabel.defaultProps = {
  type: "text",
  required: false,
  className: "",
  value: "",
  disabled: false,
};

export default TextFieldWithLabel;
