const validate = (value) => {
  if (!value) {
    return false;
  } else if (!/((?=.*\d)(?=.*[a-zA-Z]).{8,})/.test(value)) {
    return false;
  }
  return true;
};

export const validatePassword = (userPassword) => {
  const password = userPassword.trim();
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;
  const specialChars = /[^0-9A-Za-z]/g;
  if (
    !password ||
    password.length < 12 ||
    !password.match(upperCaseLetters) ||
    !password.match(numbers) ||
    !password.match(specialChars)
  ) {
    return "Password must be at least 12 characters, and contain at least one uppercase letter, one special character, and one number";
  }
  return true;
};

export default validate;
