/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import showIcon from "../../images/signup/show.svg";
import hideIcon from "../../images/signup/hide.svg";
import infoIcon from "../../images/signup/Information.svg";
import Tooltip from "../../components/Tooltip";

class PasswordFieldWithLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reveal: false,
    };
  }

  toggleReveal = () => {
    this.setState({ reveal: !this.state.reveal });
  };

  render() {
    const {
      name,
      type,
      label,
      value,
      onChange,
      onBlur,
      className,
      helperText,
    } = this.props;
    const fieldType = this.state.reveal ? "text" : type;
    const toggleText = this.state.reveal ? "Hide" : "Show";

    return (
      <div className={styles.fieldContainer}>
        <div className={styles.fieldLabelContainer}>
          <label htmlFor="lynkConfirmPassword">
            {label}
            <span className={styles.required}>*</span>
            {helperText && (
              <Tooltip tooltipText={helperText} maxWidth="514px">
                <img src={infoIcon} alt="info" />
              </Tooltip>
            )}
          </label>
        </div>
        <div className={styles.inputWrap}>
          <input
            type={fieldType}
            id={name}
            name={name}
            data-testid={name}
            className={styles[className]}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          <button
            type="button"
            className={styles.toggleBtn}
            onClick={this.toggleReveal}
          >
            <img
              className={styles.toggleBtnIcon}
              src={this.state.reveal ? hideIcon : showIcon}
              alt={`${toggleText} Icon`}
            />
          </button>
        </div>
      </div>
    );
  }
}

PasswordFieldWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  helperText: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

PasswordFieldWithLabel.defaultProps = {
  type: "password",
  className: "",
  value: "",
  helperText: "",
};

export default PasswordFieldWithLabel;
