import React from "react";
// import { isEmpty } from "lodash";
import styles from "./styles.module.scss";

// const BasicTncShortLinks = (props) =>
//   !isEmpty(props.circle) && !props.circle.isLynkCircle ? (
//     <div className={styles.basicTncLinks}>
//       I agree with{" "}
//       <a
//         target="_blank"
//         href="https://www.craftt.xyz/privacy-policy"
//         rel="noreferrer"
//       >
//         Expert Terms &amp; Conditions
//       </a>
//       , &nbsp;
//       <a
//         target="_blank"
//         href="https://www.craftt.xyz/privacy-policy"
//         rel="noreferrer"
//       >
//         Privacy Policy
//       </a>
//     </div>
//   ) : (
//     <div className={styles.basicTncLinks}>
//       I agree to Craftt&rsquo;s{" "}
//       <a
//         target="_blank"
//         href="https://www.craftt.xyz/privacy-policy"
//         rel="noreferrer"
//       >
//         Privacy Policy
//       </a>
//       , &nbsp;
//       <a
//         target="_blank"
//         href="https://www.craftt.xyz/terms-of-service"
//         rel="noreferrer"
//       >
//         Platform Terms
//       </a>
//     </div>
//   );

const BasicTncShortLinks = (props) => (
  <div className={styles.basicTncLinks}>
    I agree to Craftt&rsquo;s{" "}
    <a
      target="_blank"
      href="https://www.craftt.xyz/privacy-policy"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  </div>
);
export default BasicTncShortLinks;
