import RefreshSvg from "./refresh.svg";
import "./style.scss";
function Refresh() {
  return (
    <span>
      <img
        src={RefreshSvg}
        width={80}
        height={80}
        alt="loading"
        className="spinImage"
      />
    </span>
  );
}

export default Refresh;
