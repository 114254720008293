/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import PasswordFieldWithLabel from "../../components/PasswordFieldWithLabel";
import ButtonMVP from "../../components/ButtonMVP";
// import CheckBoxWithLabel from "../../components/CheckBoxWithLabel";
import BasicTncShortLinks from "../../components/BasicTncShortLinks";
import {
  checkNoformErrors,
  hasError,
  getErrorClass,
  validateFullForm,
  validateField,
} from "../../utils/fieldValidation";
import { VALIDATION_FIELDS, helperText } from "../../utils/constants";
import { get, isEmpty } from "lodash";
import LinkedInTncModal from "../Signup/LinkedInTncModal";
import LinkedInLoginButton from "../LinkedInLoginButton";
import { signupLinkedinMagicLynk } from "../../utils/linkedin";
import Loading from "../Loading";
import LinkedInBtn from "../../components/Signup/LinkedInBtn";
import BuilderHeaderBar from "../../components/BuilderHeaderBar";
import crafttLogo from "../../images/signup/craftt-neutral-logo.svg";

export default function SignupMagicLink(props) {
  const [formData, setFormData] = useState({
    email: props.data.email,
    magicCode: props.data.magicCode,
    password: "",
    confirmPassword: "",
    basicTnc: true,
    linkedInTncModal: false,
    userBasicTncLinkedIn: true,
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const useFormValidation = () => {
    useEffect(() => {
      function fetchData() {
        return validateFullForm(
          formData,
          fieldErrors,
          VALIDATION_FIELDS.SignUpMagicLynk
        );
      }
      setFieldErrors(fetchData());
    }, []);
  };

  useFormValidation();

  const handleChange = (key, event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData((state) => ({
      ...state,
      [key]: value,
    }));
    const formFields = {
      ...formData,
      [key]: value,
    };
    setFieldErrors(
      validateFullForm(
        formFields,
        fieldErrors,
        VALIDATION_FIELDS.SignUpMagicLynk
      )
    );
  };

  const addError = (key, error) => {
    setFieldErrors((state) => ({
      ...state,
      [key]: error,
      [`${key}Dirty`]: true,
    }));
  };

  const onBlur = ({ target }) => {
    let error;
    const { name: fieldName } = target;
    if (fieldName === "confirmPassword") {
      error = validateField(fieldName, {
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      });
    } else {
      error =
        target.type === "checkbox"
          ? validateField(fieldName, target.checked)
          : validateField(fieldName, target.value);
    }
    addError(fieldName, error);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      !isEmpty(formData.password) &&
      !isEmpty(formData.confirmPassword) &&
      formData.password === formData.confirmPassword &&
      formData.basicTnc &&
      fieldErrors.password === true
    ) {
      props.onSubmit(formData);
    }
  };

  const openLinkedInTncModal = () => {
    setFormData((state) => ({
      ...state,
      linkedInTncModal: true,
    }));
  };

  const closeLinkedInTncModal = () => {
    setFormData((state) => ({
      ...state,
      linkedInTncModal: false,
      userBasicTncLinkedIn: true,
    }));
  };

  const setupWithLinkedIn = async ({ state, code }) => {
    const signupData = {
      email: formData.email,
      magicCode: formData.magicCode,
      saveShortTnC: true,
      state,
      code,
      circle: get(props, "user.circle"),
    };
    setLoading(true);
    const result = await signupLinkedinMagicLynk({
      ...signupData,
    });
    setLoading(false);
    props.onLinkedInLogin(result);
  };

  const loginMsg =
    props && props.data && props.data.circleName
      ? `Create an account for ${props.data.circleName}`
      : "Create an account";
  const circle = get(props, "data", {});
  /* showing linkedin signup for generic login or lynk circle page.
       See https://thestraitsnetwork.slack.com/archives/C0240N6RRGR/p1632340582193800 */
  const showLinkedinSignup =
    isEmpty(circle) || get(circle, "isLynkCircle", true);
  if (loading) return <Loading />;
  return (
    <form
      className={styles["signup-magic-lynk-form"]}
      data-testid="signup-magic-lynk-form"
      onSubmit={handleSubmit}
    >
      {props.data &&
        !get(props.data, "isLynkCircle", false) &&
        !isEmpty(get(props.data, "rectangleLogo", "")) && (
          <BuilderHeaderBar
            isLynkCircle={!get(props.data, "isLynkCircle", false)}
            circle={props.data}
          />
        )}
      <header>{loginMsg}</header>
      <div className={styles.fieldWrap}>
        {props.errorMsg && (
          <div className={styles.errorMsg}>
            <span
              className={`${styles.errorIcon} fa fa-exclamation-triangle`}
            ></span>
            <span className={styles.errorText}>{props.errorMsg}</span>
          </div>
        )}
        {false && showLinkedinSignup && (
          <div>
            <React.Fragment>
              <LinkedInBtn
                id="linkedInSignup"
                text="Sign up via LinkedIn"
                onClick={openLinkedInTncModal}
              />
            </React.Fragment>
          </div>
        )}
        {false && showLinkedinSignup && (
          <div className={styles.sep}>
            <span>or</span>
          </div>
        )}
        <TextFieldWithLabel
          disabled
          label="Email"
          data-testid="email"
          className={getErrorClass("email", hasError)}
          name="email"
          type="text"
          value={formData.email}
          required
          onChange={(event) => handleChange("email", event)}
          onBlur={(event) => onBlur(event)}
        />
        {hasError("email", fieldErrors) && (
          <div className={styles.errorMsg}>{fieldErrors.email}</div>
        )}
      </div>
      <div className={styles.fieldWrap}>
        <PasswordFieldWithLabel
          helperText={helperText}
          label="Password"
          className={getErrorClass("password", hasError)}
          name="password"
          type="password"
          value={formData.password}
          required
          onChange={(event) => handleChange("password", event)}
          onBlur={(event) => onBlur(event)}
        />
        {hasError("password", fieldErrors) && (
          <div className={styles.errorMsg}>{fieldErrors.password}</div>
        )}
      </div>
      <div className={styles.fieldWrap}>
        <PasswordFieldWithLabel
          label="Confirm Password"
          className={getErrorClass("confirmPassword", hasError)}
          value={formData.confirmPassword}
          name="confirmPassword"
          type="password"
          required
          onChange={(event) => handleChange("confirmPassword", event)}
          onBlur={(event) => onBlur(event)}
        />
        {hasError("confirmPassword", fieldErrors) && (
          <div className={styles.errorMsg}>{fieldErrors.confirmPassword}</div>
        )}
      </div>
      <div className={styles.fieldWrap}>
        {/* <CheckBoxWithLabel
          checked={formData.basicTnc}
          name="basicTnc"
          required
          onChange={(event) => handleChange("basicTnc", event)}
          onBlur={(event) => onBlur(event)}
        >
          <BasicTncShortLinks />
        </CheckBoxWithLabel> */}
        <BasicTncShortLinks />
        {hasError("basicTnc", fieldErrors) && (
          <div className={styles.errorMsg}>{fieldErrors.basicTnc}</div>
        )}
      </div>
      <LinkedInTncModal
        isOpen={formData.linkedInTncModal}
        title="Sign up with LinkedIn"
        closeModal={closeLinkedInTncModal}
      >
        <p>
          You will be prompted to sign in to your LinkedIn account and authorise
          Craftt to sync your basic information from LinkedIn to your profile
        </p>
        {/* <CheckBoxWithLabel
          checked={formData.userBasicTncLinkedIn}
          name="userBasicTncLinkedIn"
          required
          onChange={(event) => handleChange("userBasicTncLinkedIn", event)}
        >
          <BasicTncShortLinks />
        </CheckBoxWithLabel> */}
        <BasicTncShortLinks />
        <div className={styles.btnsWrap}>
          <LinkedInLoginButton
            onError={(error) => this.setState({ errorMessage: error })}
            callback={(obj) =>
              setupWithLinkedIn({
                code: obj.code,
                state: obj.state,
              })
            }
          >
            {(onClick) => (
              <button
                disabled={!formData.userBasicTncLinkedIn}
                className={styles.continue}
                id="continue"
                onClick={onClick}
              >
                Continue
              </button>
            )}
          </LinkedInLoginButton>
          <button
            className={styles.cancel}
            id="cancel"
            onClick={closeLinkedInTncModal}
          >
            Cancel
          </button>
        </div>
      </LinkedInTncModal>
      <footer>
        <ButtonMVP
          className="wide"
          id="createAccount"
          disabled={!checkNoformErrors(fieldErrors)}
          onClick={handleSubmit}
        >
          Create account
        </ButtonMVP>
        {/* <Button disabled={!checkNofieldErrors(fieldErrors)}>
          Create account
        </Button> */}
      </footer>
      <img className={styles.footerImage} src={crafttLogo} alt="signup" />
    </form>
  );
}
